import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { reducedMotionQuery } from '../lib/ReducedMotion';
import { getScrollTop } from '../lib/helpers';
import animatedScroll from '../lib/animatedScroll';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, UNLOAD } from '../lib/events';

export default el => {

    const wrapper = el.querySelector('[data-wrapper]');
    const toggle = el.querySelector('button[aria-expanded]');
    const menu = toggle.nextElementSibling;
    const chapterToggles = menu.querySelector('[data-toggles]');
    const scrollable = el.querySelector('.scrollable');
    const burgerPaths = Array.from(toggle.querySelectorAll('svg path'));

    const defaultActiveChapterPanel = menu.querySelector('[data-panel]:not([hidden])') || null;

    let isExpanded = false;
    let tl = null;
    let burgerTl = null;
    let switchToThemeCreamOffset = 0;
    let disableCreamThemeOffset = true;
    let activeChapterPanel = defaultActiveChapterPanel;

    const createBurgerTl = () => {
        burgerTl = gsap.timeline({ paused: true })
            .to(burgerPaths[0], {
                y: 8,
                transformOrigin: 'center center',
                duration: 0.25,
                ease: 'none'
            }, 0)
            .to(burgerPaths[1], {
                y: -8,
                transformOrigin: 'center center',
                duration: 0.25,
                ease: 'none'
            }, 0)
            .to(burgerPaths[0], {
                rotate: -45,
                transformOrigin: 'center center',
                duration: 0.5,
                ease: 'none'
            }, 0.5)
            .to(burgerPaths[1], {
                rotate: 45,
                transformOrigin: 'center center',
                duration: 0.5,
                ease: 'none'
            }, 0.5);
    };

    const destroyTl = () => {
        if (tl) {
            gsap.killTweensOf(tl);
            tl.kill();
            tl = null;
        }
        gsap.killTweensOf([wrapper, menu]);
        gsap.set([wrapper, menu, chapterToggles], { clearProps: 'width,height' });
    };

    const createTl = () => {
        if (!burgerTl) {
            createBurgerTl();
        }
        destroyTl();
        gsap.set(menu, { maxWidth: 'none' });
        const { width: menuWidth } = menu.getBoundingClientRect();
        const { width: wrapperWidth, height: toHeight } = wrapper.getBoundingClientRect();
        const borderWidth = parseInt(window.getComputedStyle(wrapper.firstElementChild)
            .borderWidth
            .replace('px', ''), 10) * 2;
        tl = gsap.timeline({ paused: true });
        if (menuWidth !== wrapperWidth) {
            tl.to(wrapper, {
                width: menuWidth + borderWidth,
                duration: 1,
                ease: 'none'
            }, 0);
        } else {
            tl.set(wrapper, { clearProps: 'width' }, 0);
        }
        gsap.set(menu, { clearProps: 'maxWidth' });
        tl
            .fromTo(wrapper, {
                height: toggle.getBoundingClientRect().height + borderWidth
            }, {
                height: toHeight,
                duration: 1,
                ease: 'none'
            }, 0)
            .fromTo(menu, { opacity: 0 }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Cubic.easeIn'
            }, 0.5)
            .set(wrapper, { clearProps: 'height' });
    };

    const resetTl = () => {
        destroyTl();
        if (isExpanded) {
            createTl();
            tl.progress(1);
        }
    };

    const closeActiveChapterPanel = (tween = true) => {
        if (!activeChapterPanel) {
            return;
        }
        const oldActiveChapterPanel = activeChapterPanel;
        activeChapterPanel = null;
        const { height: fromHeight } = menu.getBoundingClientRect();
        menu.querySelectorAll('button[aria-controls][aria-expanded]')
            .forEach(chapterToggle => {
                chapterToggle.removeAttribute('tabindex');
                chapterToggle.setAttribute('aria-expanded', 'false');
                chapterToggle.style.pointerEvents = '';
            });
        if (!document.activeElement || oldActiveChapterPanel.contains(document.activeElement)) {
            menu.querySelector(`button[aria-controls="${oldActiveChapterPanel.id}"]`)
                .focus({ preventScroll: true });
        }
        scrollable.scrollTo(0, 0);
        resetTl();
        if (tween) {
            oldActiveChapterPanel.hidden = true;
            const { height: toHeight } = menu.getBoundingClientRect();
            oldActiveChapterPanel.hidden = false;
            gsap.timeline({
                onComplete() {
                    oldActiveChapterPanel.hidden = true;
                    resetTl();
                }
            })
                .to(oldActiveChapterPanel, {
                    opacity: 0,
                    duration: 0.15
                }, 0)
                .fromTo(chapterToggles, { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.15
                }, 0)
                .fromTo(menu, { height: fromHeight }, {
                    height: toHeight,
                    duration: 0.5,
                    ease: 'Power2.easeInOut'
                }, 0)
                .set(menu, { clearProps: 'height' });
        } else {
            oldActiveChapterPanel.hidden = true;
            gsap.killTweensOf([menu, chapterToggles, oldActiveChapterPanel]);
            gsap.set([menu, chapterToggles, oldActiveChapterPanel], { clearProps: 'all' });
        }
    };

    const setActiveChapterPanel = (chapterPanel, tween = true) => {
        if (activeChapterPanel) {
            return;
        }
        const { height: fromHeight } = menu.getBoundingClientRect();
        activeChapterPanel = chapterPanel;
        activeChapterPanel.hidden = false;
        chapterToggles.querySelectorAll('button')
            .forEach(chapterToggle => {
                chapterToggle.setAttribute('tabIndex', '-1');
                chapterToggle.style.pointerEvents = 'none';
                if (chapterToggle.getAttribute('aria-controls') === activeChapterPanel.id) {
                    chapterToggle.setAttribute('aria-expanded', 'true');
                } else {
                    chapterToggle.setAttribute('aria-expanded', 'false');
                }
            });
        const closeBtn = chapterPanel.querySelector('button[aria-expanded]');
        closeBtn.setAttribute('aria-expanded', 'true');
        closeBtn.focus({ preventScroll: true });
        scrollable.scrollTo(0, 0);
        resetTl();
        if (tween) {
            gsap.timeline({
                onComplete() {
                    resetTl();
                }
            })
                .to(chapterToggles, {
                    opacity: 0,
                    duration: 0.3
                }, 0)
                .fromTo(activeChapterPanel, { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.3
                }, 0)
                .fromTo(menu, { height: fromHeight }, {
                    height: 'auto',
                    duration: 0.5,
                    ease: 'Power2.easeInOut'
                }, 0)
                .set(menu, { clearProps: 'height' });
        } else {
            gsap.killTweensOf([menu, chapterToggles, activeChapterPanel]);
            gsap.set([menu, chapterToggles, activeChapterPanel], { clearProps: 'all' });
        }
    };

    const onChapterPanelToggleClick = e => {
        const { currentTarget: chapterPanelToggle } = e;
        const chapterPanel = document.getElementById(chapterPanelToggle.getAttribute('aria-controls'));
        if (!chapterPanel) {
            console.warn('Chapter panel not found');
        }
        if (chapterPanelToggle.getAttribute('aria-expanded') === 'true') {
            closeActiveChapterPanel();
        } else {
            setActiveChapterPanel(chapterPanel);
        }
    };

    const afterClose = () => {
        destroyTl();
        scrollable.scrollTo(0, 0);
        menu.hidden = true;
        toggle.setAttribute('aria-expanded', 'false');
        el.classList.remove('is-open', 'is-closing');
        menu.querySelectorAll('button[aria-controls^="menupanel"]')
            .forEach(chapterPanelToggle => {
                chapterPanelToggle.removeEventListener('click', onChapterPanelToggleClick);
            });
        if (defaultActiveChapterPanel) {
            setActiveChapterPanel(defaultActiveChapterPanel, false);
        } else {
            closeActiveChapterPanel(false);
        }
        if (!document.activeElement || document.activeElement === document.body || menu.contains(document.activeElement)) {
            toggle.focus({ preventScroll: true });
        }
        burgerTl.pause(0);
    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        menu.hidden = false;
        toggle.setAttribute('aria-expanded', 'true');
        el.classList.add('is-open');
        const { top } = menu.parentNode.getBoundingClientRect();
        if (top < Viewport.height * 0.1) {
            animatedScroll(getScrollTop() + top - (Viewport.height * 0.1));
        }
        if (!tl) {
            createTl();
        }
        gsap.to(tl, {
            progress: 1,
            duration: 0.5,
            ease: 'Power2.easeInOut'
        });
        menu.querySelectorAll('button[aria-controls^="menupanel"]')
            .forEach(chapterPanelToggle => {
                chapterPanelToggle.addEventListener('click', onChapterPanelToggleClick);
            });
        gsap.to(burgerTl, {
            progress: 1,
            duration: 0.5,
            ease: 'Power2.easeInOut'
        });
    };

    const collapse = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        if (!tween) {
            afterClose();
            return;
        }
        if (!tl) {
            createTl();
            tl.progress(1);
        }
        el.classList.add('is-closing');
        gsap.timeline()
            .to(tl, {
                progress: 0,
                duration: 0.5,
                ease: 'Power2.easeInOut',
                onComplete: afterClose
            }, 0)
            .add(() => {
                if (!isExpanded) {
                    el.classList.remove('is-open');
                }
            }, 0.3);
        gsap.to(burgerTl, {
            progress: 0,
            duration: 0.5,
            ease: 'Power2.easeInOut'
        });
    };

    const onToggleClick = e => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const onBodyClickOrFocus = e => {
        if (!isExpanded) {
            return;
        }
        const { target } = e;
        if (target === wrapper || wrapper.contains(target)) {
            return;
        }
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded || e.key !== 'Escape') {
            return;
        }
        collapse();
    };

    // Detect if we've scrolled past the hero
    const onScroll = () => {
        if (disableCreamThemeOffset) {
            return;
        }
        if (getScrollTop() > switchToThemeCreamOffset) {
            el.classList.add('!theme-cream');
        } else {
            el.classList.remove('!theme-cream');
        }
    };

    const onResize = () => {
        resetTl();
        const pageHero = document.getElementById('hero');
        if (!pageHero) {
            switchToThemeCreamOffset = 0;
        } else {
            const {
                top,
                height
            } = el.firstElementChild.getBoundingClientRect();
            const {
                top: wrapperTop,
                height: wrapperHeight
            } = wrapper.getBoundingClientRect();
            const { height: pageHeroHeight } = pageHero.getBoundingClientRect();
            switchToThemeCreamOffset = ((pageHero.offsetTop + pageHeroHeight) - Viewport.height) + (height - (wrapperHeight / 2)) - (wrapperTop - top);
        }
        onScroll();
    };

    const resizeHandler = () => {
        requestAnimationFrame(onResize);
    };

    const onReducedMotionChange = () => {
        const motionReduced = !!reducedMotionQuery.matches;
        if (!motionReduced) {
            el.classList.remove('!theme-cream');
        }
        disableCreamThemeOffset = !motionReduced;
        onResize();
    };

    const onKeyDownHandler = e => {
        if (!isExpanded || (e.key !== 'Tab' && e.keyCode !== 9)) {
            return;
        }
        const firstFocusable = toggle;
        let lastFocusable;
        if (activeChapterPanel) {
            lastFocusable = Array.from(activeChapterPanel.querySelectorAll('button,a'))
                .pop();
        } else {
            lastFocusable = Array.from(menu.querySelectorAll('button[data-chapter-toggle]'))
                .pop() || firstFocusable;
        }
        if (e.shiftKey && document.activeElement === firstFocusable) {
            e.preventDefault();
            lastFocusable.focus();
        } else if (!e.shiftKey && document.activeElement === lastFocusable) {
            e.preventDefault();
            firstFocusable.focus();
        }
    };

    const onUnload = () => {
        collapse();
    };

    const onPageShow = e => {
        if (!e.persisted) {
            return;
        }
        collapse(false);
    };

    const init = () => {
        el.addEventListener('keydown', onKeyDownHandler);
        toggle.addEventListener('click', onToggleClick);
        window.addEventListener('scroll', onScroll, {
            capture: false,
            passive: true
        });
        window.addEventListener('resize', resizeHandler, {
            capture: false,
            passive: true
        });
        window.addEventListener('orientationchange', resizeHandler, {
            capture: false,
            passive: true
        });
        document.body.addEventListener('click', onBodyClickOrFocus);
        document.body.addEventListener('focusin', onBodyClickOrFocus);
        document.body.addEventListener('keyup', onBodyKeyUp);
        Dispatch.on(UNLOAD, onUnload);
        // If motion is *not* reduced, the logic that forcibly sets the menu bar to theme-cream should be disabled, because the menu bar's theme will be controlled via the ThemeSwitcher
        try {
            reducedMotionQuery.addEventListener('change', onReducedMotionChange);
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
        onReducedMotionChange();
        // Fix for Safari's back button (back-forward cache)
        window.addEventListener('pageshow', onPageShow);
        if (ENV !== 'production') {
            Dispatch.emit(COMPONENT_INIT);
        }
    };

    const destroy = () => {
        el.removeEventListener('keydown', onKeyDownHandler);
        toggle.removeEventListener('click', onToggleClick);
        window.removeEventListener('scroll', onScroll, {
            capture: false,
            passive: true
        });
        window.removeEventListener('resize', resizeHandler, {
            capture: false,
            passive: true
        });
        window.removeEventListener('orientationchange', resizeHandler, {
            capture: false,
            passive: true
        });
        document.body.removeEventListener('click', onBodyClickOrFocus);
        document.body.removeEventListener('focusin', onBodyClickOrFocus);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        Dispatch.off(UNLOAD, onUnload);
        collapse(false);
        destroyTl();
        if (burgerTl) {
            burgerTl.kill();
            burgerTl = null;
        }
        window.removeEventListener('pageshow', onPageShow);
        try {
            reducedMotionQuery.removeEventListener('change', onReducedMotionChange);
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
    };

    return {
        init,
        destroy
    };

};
