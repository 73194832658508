import Viewport from '../core/Viewport';
import { getScrollTop } from './helpers';
import { reducedMotionQuery } from './ReducedMotion';

const TRANSITION_DURATION = 0.5; // seconds

let hasInited = false;
let defaultTheme = null;
let themeSections = null;
let themeSectionsData = [];
let currentSectionIndex = 0;
let transitionTimer = null;
let disableTransition = true;

const getDocumentThemeClasses = () => Array.from(document.documentElement.classList).filter(className => className.startsWith('theme-'));

const updateSectionsData = () => {
    themeSectionsData = [{
        offsetY: 0,
        viewportOffset: 0,
        themeClass: `theme-${defaultTheme}`
    }];
    themeSections.forEach(item => {
        const themeClass = `theme-${item.dataset.theme || defaultTheme}`;
        themeSectionsData.push({
            offsetY: item.offsetTop,
            viewportOffset: Viewport.height * parseFloat(item.dataset.themeOffset || '0.9'),
            themeClass
        });
        item.classList.replace(themeClass, `x-${themeClass}`);
    });
};

const updateTheme = themeData => {

    const { documentElement } = document;

    if (documentElement.classList.contains(themeData.themeClass)) {
        return;
    }

    // Remove existing theme classes
    documentElement.classList.remove(...getDocumentThemeClasses());

    if (transitionTimer) {
        clearTimeout(transitionTimer);
    }

    let duration = TRANSITION_DURATION;
    if (disableTransition) {
        duration = 0;
    }

    documentElement.classList.add('transition-theme');
    documentElement.style.setProperty('--color-transition-duration', `${duration}s`);
    transitionTimer = setTimeout(() => {
        documentElement.classList.remove('transition-theme');
        documentElement.style.removeProperty('--color-transition-duration');
    }, (duration * 1000) + 100);

    documentElement.classList.add(themeData.themeClass);
};

const scroll = () => {
    let newCurrent = 0;
    const scrollTop = getScrollTop();

    themeSectionsData.forEach((data, index) => {
        if ((scrollTop + data.viewportOffset) < data.offsetY) {
            return;
        }
        newCurrent = index;
    });

    if ((currentSectionIndex !== newCurrent)) {
        updateTheme(themeSectionsData[newCurrent]);
        currentSectionIndex = newCurrent;
    }
};

const resize = () => {
    updateSectionsData();
    scroll();
};

const resizeHandler = () => {
    requestAnimationFrame(resize);
};

const destroy = () => {
    if (!hasInited) {
        return;
    }
    window.removeEventListener('scroll', scroll, {
        capture: false,
        passive: true
    });
    window.removeEventListener('resize', resizeHandler, {
        capture: false,
        passive: true
    });
    window.removeEventListener('orientationchange', resizeHandler, {
        capture: false,
        passive: true
    });
    hasInited = false;
    defaultTheme = null;
    themeSections = null;
    themeSectionsData = [];
    currentSectionIndex = 0;
    transitionTimer = null;
    disableTransition = true;
    document.querySelectorAll('[class*="x-theme-"]')
        .forEach(item => {
            item.className = item.className.replace('x-theme-', 'theme-');
        });
};

const init = () => {

    if (hasInited) {
        return;
    }

    hasInited = true;
    themeSections = Array.from(document.querySelectorAll('[data-theme]'));

    window.addEventListener('scroll', scroll, {
        capture: false,
        passive: true
    });
    window.addEventListener('resize', resizeHandler, {
        capture: false,
        passive: true
    });
    window.addEventListener('orientationchange', resizeHandler, {
        capture: false,
        passive: true
    });

    defaultTheme = document.documentElement.dataset.defaultTheme;

    if (!document.documentElement.classList.contains(`theme-${defaultTheme}`)) {
        disableTransition = false;
        requestAnimationFrame(resize);
        return;
    }

    resize();
    disableTransition = false;

};

export default {
    init,
    destroy
};
