import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Revealer from './Revealer';
import Dispatch from '../core/Dispatch';
import { UNLOAD } from './events';

const menu = document.getElementById('menu-wrapper');
const main = document.getElementById('main');
const footer = document.getElementById('bottom');

let hasInited = false;

const getRevealedTweens = () => $('[data-revealed]')
    .get()
    .reduce((carry, el) => {
        const tween = Viewport.visible(el) ? Revealer.tweens.get(el) : null;
        if (!tween) {
            return carry;
        }
        tween.pause(tween.time());
        return carry.concat(tween);
    }, []);

const isKeyCombo = e => e && (e.which === 2 || e.button === 4 || e.metaKey || e.shiftKey);

const clickHandler = e => {

    const { triggerTarget: target } = e;
    const href = target.getAttribute('href');
    const host = window.top.location.host.split(':')[0];

    // Check if the click was eligble for transition
    if (target.hasAttribute('data-no-transition') || href[0] === '#' || target.getAttribute('target') === '_blank' || isKeyCombo(e) || target.hasAttribute('data-filter') || target.hasAttribute('download') || href.indexOf(host) === -1 || href.indexOf('actions/debug') > -1) {
        return true;
    }

    e.preventDefault();

    Dispatch.emit(UNLOAD);

    if (window.sessionStorage && !Viewport.scrollTop) {
        document.documentElement.classList.add('persist-branding');
        window.sessionStorage.setItem('persist-branding', true);
    } else {
        document.documentElement.classList.remove('persist-branding');
    }

    // Set current theme to a cookie
    const currentTheme = (Array.from(document.documentElement.classList).filter(className => className.startsWith('theme-'))[0] || '').slice(('theme-').length);
    if (window.sessionStorage && currentTheme) {
        window.sessionStorage.setItem('from-theme', currentTheme);
    }

    // Rewind revealed tweens
    const revealedTweens = getRevealedTweens();

    if (revealedTweens.length) {
        gsap
            .to(revealedTweens, {
                progress: 0,
                duration: 0.35,
                stagger: 0.01,
                ease: 'none'
            });
    }

    gsap.to([menu, main, footer], {
        opacity: 0,
        duration: 0.29,
        ease: 'Quad.easeIn'
    });

    // Redirect
    setTimeout(() => {
        window.location.href = href;
    }, 300);

    return false;
};

const initLinks = () => {
    $('body')
        .on('click', 'a[href]', clickHandler);
};

const onPageShow = e => {
    if (!e.persisted) {
        return;
    }
    setTimeout(() => {
        // Reset revealed tweens
        gsap.set([menu, main, footer], { clearProps: 'opacity' });
        const revealedTweens = getRevealedTweens();
        if (revealedTweens.length) {
            gsap.set(revealedTweens, { progress: 1 });
        }
    }, 0);
};

const init = () => {

    if (hasInited) {
        return;
    }

    if (window.sessionStorage) {
        window.sessionStorage.removeItem('persist-branding');
        window.sessionStorage.removeItem('from-theme');
    }

    hasInited = true;

    initLinks();

    // Fix for Safari's back button (back-forward cache)
    window.addEventListener('pageshow', onPageShow);

};

const destroy = () => {
    if (!hasInited) {
        return;
    }
    hasInited = false;
    $('body').off('click', 'a[href]', clickHandler);
    window.removeEventListener('pageshow', onPageShow);
};

export default ({
    init,
    destroy
});
