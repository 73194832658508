import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default toggleButton => {

    const popup = toggleButton.nextElementSibling;
    const closeBtn = popup.querySelector('button');

    let isExpanded = false;
    let tl;

    const positionPopup = () => {
        if (!isExpanded) {
            return;
        }
        gsap.set(popup, { clearProps: 'all' });
        let outerMargin = 16;
        const firstWrapper = popup.closest('.wrapper');
        if (firstWrapper) {
            outerMargin = parseInt(window.getComputedStyle(firstWrapper).paddingLeft.replace('px', ''), 10);
        }
        const { left } = popup.getBoundingClientRect();
        if (left < outerMargin) {
            gsap.set(popup, {
                left: 0,
                xPercent: 0,
                marginLeft: 0
            });
        }
    };

    const close = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        toggleButton.setAttribute('aria-expanded', 'false');
        if (!document.activeElement || document.activeElement === document.body || popup.contains(document.activeElement)) {
            toggleButton.focus({ preventScroll: true });
        }
        if (tl) {
            tl.kill();
            tl = null;
        }
        const afterClose = () => {
            if (tl) {
                tl.kill();
                tl = null;
            }
            popup.hidden = true;
        };
        if (!tween) {
            afterClose();
            return;
        }
        tl = gsap.timeline({
            onComplete: afterClose
        })
            .to(popup, {
                opacity: 0,
                duration: 0.15
            }, 0);
    };

    const open = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        toggleButton.setAttribute('aria-expanded', 'true');
        popup.hidden = false;
        positionPopup();
        closeBtn.focus({ preventScroll: true });
        if (tl) {
            tl.kill();
            tl = null;
        }
        tl = gsap.timeline({
            onComplete() {
                tl.kill();
                tl = null;
            }
        })
            .fromTo(popup, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .fromTo(popup, { y: 10 }, {
                y: 0,
                duration: 0.5,
                ease: 'Expo.easeOut'
            }, 0);
    };

    const toggle = () => {
        if (isExpanded) {
            close();
        } else {
            open();
        }
    };

    const onBodyClickOrFocus = e => {
        if (!isExpanded || e.target === toggle || toggleButton.contains(e.target) || e.target === popup || popup.contains(e.target)) {
            return;
        }
        close();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded) {
            return;
        }
        if (e.key === 'Escape' || e.keyCode === 27) {
            close();
        }
    };

    const onToggleClick = () => {
        toggle();
    };

    const onCloseBtnClick = () => {
        close();
    };

    const onResize = () => {
        positionPopup();
    };

    const init = () => {
        document.body.addEventListener('click', onBodyClickOrFocus);
        document.body.addEventListener('focusin', onBodyClickOrFocus);
        document.body.addEventListener('keyup', onBodyKeyUp);
        toggleButton.addEventListener('click', onToggleClick);
        closeBtn.addEventListener('click', onCloseBtnClick);
        Viewport.on('resize', onResize);
        if (ENV !== 'production') {
            Dispatch.emit(COMPONENT_INIT);
        }
    };

    const destroy = () => {
        close(false);
        document.body.removeEventListener('click', onBodyClickOrFocus);
        document.body.removeEventListener('focusin', onBodyClickOrFocus);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        toggleButton.removeEventListener('click', onToggleClick);
        closeBtn.removeEventListener('click', onCloseBtnClick);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
