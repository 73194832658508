import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props) => {

    const button = el.querySelector('[data-button]');
    const embed = el.querySelector('[data-embed]');
    const { embedCode } = props;

    const loadPlayer = e => {
        if (e) {
            e.preventDefault();
        }
        button.setAttribute('tabIndex', '-1');
        embed.innerHTML = embedCode;
        gsap.timeline()
            .to(embed, {
                duration: 1,
                autoAlpha: 1,
                ease: 'Power2.easeInOut'
            });
    };

    const onButtonKeyDown = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const onButtonKeyUp = e => {
        e.preventDefault();
        if (e.key === 'Enter' || e.key === ' ') {
            loadPlayer();
        }
    };

    const init = () => {
        button.removeAttribute('href');
        button.removeAttribute('target');
        button.removeAttribute('rel');
        button.setAttribute('role', 'button');
        button.setAttribute('tabIndex', '0');
        button.addEventListener('keydown', onButtonKeyDown);
        button.addEventListener('keyup', onButtonKeyUp);
        button.addEventListener('click', loadPlayer);
        if (ENV !== 'production') {
            Dispatch.emit(COMPONENT_INIT);
        }
    };

    const destroy = () => {
        button.removeEventListener('keydown', onButtonKeyDown);
        button.removeEventListener('keyup', onButtonKeyUp);
        button.removeEventListener('click', loadPlayer);
    };

    return {
        init,
        destroy
    };
};
