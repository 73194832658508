import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ThemeSwitcher from './lib/ThemeSwitcher';
import animatedScroll from './lib/animatedScroll';
import { reducedMotionQuery } from './lib/ReducedMotion';
import Revealer from './lib/Revealer';
import PageTransition from './lib/PageTransition';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();

    const isPreview = document.documentElement.classList.contains('is-iframe') && document.documentElement.classList.contains('is-preview');

    const onReduceMotionChange = () => {
        const reduceMotion = !!reducedMotionQuery.matches;
        if (!reduceMotion) {
            ThemeSwitcher.init();
            if (!isPreview) {
                Revealer.init();
                PageTransition.init();
            }
        } else {
            ThemeSwitcher.destroy();
            Revealer.destroy();
            PageTransition.destroy();
        }
    };

    onReduceMotionChange();

    try {
        reducedMotionQuery.addEventListener('change', onReduceMotionChange);
    } catch (error) {
        // Don't care.
    }

    window.addEventListener('popstate', () => {
        window.location.reload();
    });

    $('body')
        .on('click', 'a[href^="#"]', e => {
            e.preventDefault();
            const targetId = e.triggerTarget.getAttribute('href');
            const target = $(targetId).get(0);
            if (!target) {
                console.warn(`Target ${targetId} not found`);
                return true;
            }
            e.preventDefault();
            animatedScroll(target);
            return false;
        });
};

require('doc-ready')(init);
